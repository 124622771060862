var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-carousel',{attrs:{"height":"650","hide-delimiter-background":"","show-arrows-on-hover":"","cycle":!_vm.demo}},[_vm._l((_vm.headerContents),function(headerContent,index){return _c('v-carousel-item',{key:(index + "-" + (headerContent.id))},[_c('v-menu',{staticClass:"list-position",class:{
        'list-position-xlOnly': _vm.$vuetify.breakpoint.xlOnly,
        'list-position-lgAndUp': _vm.$vuetify.breakpoint.lg,
        'list-position-mdAndUp': _vm.$vuetify.breakpoint.md,
        'list-position-smAndUp': _vm.$vuetify.breakpoint.sm,
        'list-position-xs': _vm.$vuetify.breakpoint.xsOnly,
        'list-position-xxs':
          _vm.$vuetify.breakpoint.width <= 423 && _vm.$vuetify.breakpoint.width > 378,
        'list-position-xxxs': _vm.$vuetify.breakpoint.width <= 378
      },attrs:{"attach":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.showOptions)?_c('v-btn',_vm._g(_vm._b({staticClass:"content-absolute button-primary",attrs:{"elevation":"7","fab":"","small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.modifyContent(headerContent)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteContent(headerContent)}}},[_c('v-list-item-title',[_vm._v("Eliminar")])],1)],1)],1),(!_vm.demo)?_c('v-img',{attrs:{"src":headerContent.image,"lazy-src":headerContent.image,"height":"650"}},[_c('div',{staticClass:"image-overlay fill-height"})]):_vm._e(),_c('v-container',{staticClass:"fill-height content-position"},[(_vm.demo)?_c('v-img',{attrs:{"src":headerContent.image,"lazy-src":headerContent.image,"height":"650"}},[_c('div',{staticClass:"image-overlay fill-height"})]):_vm._e()],1),_c('v-container',{staticClass:"fill-height content-position"},[_c('v-row',{staticClass:"fill-height pb-4",attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticClass:"fill-height d-flex justify-center align-end pb-10"},[_c('div',{staticClass:"text-center text-sm-h5 white-space-break-spaces"},[_vm._v(" "+_vm._s(headerContent.content)+" ")])])],1)],1)],1)}),_c('v-container',{staticClass:"fill-height fit-content-width",class:_vm.$vuetify.breakpoint.smAndUp
        ? 'content-title'
        : _vm.demo
        ? 'content-title-xs content-position'
        : 'content-position'},[_c('v-row',{staticClass:"fill-height d-flex justify-center align-start fit-content-width"},[_c('v-col',{staticClass:"pb-0 fit-content-width",attrs:{"cols":"12"}},[(!(_vm.$vuetify.breakpoint.xsOnly && _vm.demo))?_c('br'):_vm._e(),(!(_vm.$vuetify.breakpoint.xsOnly && _vm.demo))?_c('br'):_vm._e(),(!(_vm.$vuetify.breakpoint.xsOnly && _vm.demo))?_c('br'):_vm._e(),(!(_vm.$vuetify.breakpoint.xsOnly && _vm.demo))?_c('br'):_vm._e(),_c('h1',{staticClass:"title-color px-5 py-2 text-center",class:{
            'fit-content-width': _vm.$vuetify.breakpoint.smAndUp,
            'text-sm-h3': !_vm.demo,
            'text-subtitle-1 text-sm-h4 text-md-h3': _vm.demo
          }},[_vm._v(" VENUSA.LIVE ")]),_c('h2',{staticClass:"subtitle-color px-5 py-2 text-center",class:{
            'fit-content-width': _vm.$vuetify.breakpoint.smAndUp,
            'text-sm-h4': !_vm.demo,
            'text-subtitle-2 text-sm-h5 text-md-h4': _vm.demo
          }},[_vm._v(" CONSTELADORA FAMILIAR ")])])],1)],1),_c('v-container',{staticClass:"content-section-name"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","justify":"center","align":"center"}},[_c('br'),_c('br'),_c('h1',{staticClass:"section-title-color px-5 py-2 text-center",class:{
            'fit-content-width': _vm.$vuetify.breakpoint.smAndUp,
            'text-sm-h2': !_vm.demo,
            'text-subtitle-1 text-sm-h4 text-md-h3': _vm.demo
          }},[_vm._v(" "+_vm._s(_vm.currentSectionName)+" ")])])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }