<template>
  <v-carousel
    height="650"
    hide-delimiter-background
    show-arrows-on-hover
    :cycle="!demo"
  >
    <v-carousel-item
      v-for="(headerContent, index) in headerContents"
      :key="`${index}-${headerContent.id}`"
    >
      <v-menu
        attach
        offset-y
        class="list-position"
        :class="{
          'list-position-xlOnly': $vuetify.breakpoint.xlOnly,
          'list-position-lgAndUp': $vuetify.breakpoint.lg,
          'list-position-mdAndUp': $vuetify.breakpoint.md,
          'list-position-smAndUp': $vuetify.breakpoint.sm,
          'list-position-xs': $vuetify.breakpoint.xsOnly,
          'list-position-xxs':
            $vuetify.breakpoint.width <= 423 && $vuetify.breakpoint.width > 378,
          'list-position-xxxs': $vuetify.breakpoint.width <= 378
        }"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="showOptions"
            elevation="7"
            fab
            small
            v-bind="attrs"
            v-on="on"
            icon
            class="content-absolute button-primary"
          >
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="modifyContent(headerContent)">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteContent(headerContent)">
            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-img
        v-if="!demo"
        :src="headerContent.image"
        :lazy-src="headerContent.image"
        height="650"
      >
        <div class="image-overlay fill-height"></div>
      </v-img>
      <v-container class="fill-height content-position">
        <v-img
          v-if="demo"
          :src="headerContent.image"
          :lazy-src="headerContent.image"
          height="650"
        >
          <div class="image-overlay fill-height"></div>
        </v-img>
      </v-container>
      <v-container class="fill-height content-position">
        <v-row class="fill-height pb-4" align="center" justify="end">
          <v-col class="fill-height d-flex justify-center align-end pb-10">
            <div class="text-center text-sm-h5 white-space-break-spaces">
              {{ headerContent.content }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>
    <v-container
      class="fill-height fit-content-width"
      :class="
        $vuetify.breakpoint.smAndUp
          ? 'content-title'
          : demo
          ? 'content-title-xs content-position'
          : 'content-position'
      "
    >
      <v-row
        class="fill-height d-flex justify-center align-start fit-content-width"
      >
        <v-col cols="12" class="pb-0 fit-content-width">
          <br v-if="!($vuetify.breakpoint.xsOnly && demo)" />
          <br v-if="!($vuetify.breakpoint.xsOnly && demo)" />
          <br v-if="!($vuetify.breakpoint.xsOnly && demo)" />
          <br v-if="!($vuetify.breakpoint.xsOnly && demo)" />
          <h1
            class="title-color px-5 py-2 text-center"
            :class="{
              'fit-content-width': $vuetify.breakpoint.smAndUp,
              'text-sm-h3': !demo,
              'text-subtitle-1 text-sm-h4 text-md-h3': demo
            }"
          >
            VENUSA.LIVE
          </h1>
          <h2
            class="subtitle-color px-5 py-2 text-center"
            :class="{
              'fit-content-width': $vuetify.breakpoint.smAndUp,
              'text-sm-h4': !demo,
              'text-subtitle-2 text-sm-h5 text-md-h4': demo
            }"
          >
            CONSTELADORA FAMILIAR
          </h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="content-section-name">
      <v-row class justify="center" align="center">
        <v-col cols="12" class="pb-0" justify="center" align="center">
          <br />
          <br />
          <h1
            class="section-title-color px-5 py-2 text-center"
            :class="{
              'fit-content-width': $vuetify.breakpoint.smAndUp,
              'text-sm-h2': !demo,
              'text-subtitle-1 text-sm-h4 text-md-h3': demo
            }"
          >
            {{ currentSectionName }}
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </v-carousel>
</template>

<script>
import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");

export default {
  name: "website-header",
  props: {
    content: {
      type: Array,
      required: true
    },
    demo: {
      type: Boolean,
      default: false
    },
    showOptions: {
      type: Boolean,
      default: false
    },
    previewImage: {
      type: String,
      default: ""
    },
    currentSectionName: {
      type: String,
      default: "HOME"
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteContent(content) {
      let contentToSend = this.content.find(headerContent => {
        return headerContent.id === content.id;
      });
      this.$parent.$emit("deleteContent", contentToSend);
    },
    modifyContent(content) {
      let contentToSend = this.content.find(headerContent => {
        return headerContent.id === content.id;
      });
      this.$parent.$emit("modifyContent", contentToSend);
    }
  },
  computed: {
    headerContents() {
      let contents = [];
      this.content.forEach(headerContent => {
        let transformedContent = Object.assign({}, headerContent);
        if (transformedContent.image) {
          if (this.previewImage) {
            transformedContent.image = this.previewImage;
          } else {
            transformedContent.image = `${WebsiteRepository.getRoute()}/${
              transformedContent.image
            }`;
          }
        }
        contents.push(transformedContent);
      });
      return contents;
    }
  }
};
</script>

<style scoped>
.image-overlay {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6) 60%, #62d5cc 100%);
}
.content-position {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.content-title {
  position: absolute;
  top: 0;
  left: 5%;
}
.content-title-xs {
  top: 20%;
}
.content-section-name {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
}
.content-absolute {
  position: absolute;
  margin: 25px;
  background-color: white;
  z-index: 2;
  top: 0;
  right: 0;
}
.button-primary {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
  background-color: white !important;
}
.title-color {
  background-color: var(--v-secondary-base);
  color: white;
}
.section-title-color {
  border: solid 2px white;
  color: white;
}
.fit-content-width {
  width: fit-content;
}
.subtitle-color {
  background-color: white;
  color: var(--v-secondary-base);
}
.white-space-break-spaces {
  white-space: break-spaces;
}
.list-position-xlOnly ::v-deep .v-menu__content {
  left: 91% !important;
}
.list-position-lgAndUp ::v-deep .v-menu__content {
  left: 89% !important;
}
.list-position-mdAndUp ::v-deep .v-menu__content {
  left: 86% !important;
}
.list-position-smAndUp ::v-deep .v-menu__content {
  left: 81% !important;
}
.list-position-xs ::v-deep .v-menu__content {
  left: 65% !important;
  top: 5% !important;
}
.list-position-xxs ::v-deep .v-menu__content {
  left: 60% !important;
  top: 5% !important;
}
.list-position-xxxs ::v-deep .v-menu__content {
  left: 30% !important;
  top: 5% !important;
}
</style>
