var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"content-relative",class:{
    'background-clean':
      _vm.colorSet == _vm.setOfColors.CLEAN || _vm.colorSet == _vm.setOfColors.PRIMARY,
    'background-secondary': _vm.colorSet == _vm.setOfColors.SECONDARY
  },attrs:{"fluid":""}},[(_vm.showOptions)?_c('v-menu',{class:{
      'list-position-xlOnly': _vm.$vuetify.breakpoint.xlOnly,
      'list-position-lgAndUp': _vm.$vuetify.breakpoint.lg,
      'list-position-mdAndUp': _vm.$vuetify.breakpoint.md,
      'list-position-smAndUp': _vm.$vuetify.breakpoint.sm,
      'list-position-xs': _vm.$vuetify.breakpoint.xsOnly,
      'list-position-xxs':
        _vm.$vuetify.breakpoint.width <= 423 && _vm.$vuetify.breakpoint.width > 378,
      'list-position-xxxs': _vm.$vuetify.breakpoint.width <= 378
    },attrs:{"attach":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"content-absolute",class:{
          'title-clean':
            _vm.colorSet == _vm.setOfColors.CLEAN || _vm.colorSet == _vm.setOfColors.PRIMARY,
          'title-secondary': _vm.colorSet == _vm.setOfColors.SECONDARY
        },attrs:{"elevation":"7","fab":"","small":"","drak":_vm.colorSet == _vm.setOfColors.CLEAN,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.colorSet == _vm.setOfColors.PRIMARY
              ? 'primary'
              : _vm.colorSet == _vm.setOfColors.SECONDARY
              ? 'white'
              : 'primary'}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2854336232)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.modifyContent(_vm.info)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),(!this.onlyCard)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteContent(_vm.info)}}},[_c('v-list-item-title',[_vm._v("Eliminar")])],1):_vm._e()],1)],1):_vm._e(),_c('v-row',{class:{ 'px-10': _vm.$vuetify.breakpoint.smAndUp },attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"image-display",attrs:{"cols":"12","md":_vm.info.image ? '6' : '12',"justify":"center","align":"center"}},[(_vm.info.image)?_c('v-img',{staticClass:"info-card-image-background",attrs:{"src":_vm.imageFile,"lazy-src":_vm.imageFile}}):(_vm.info.video)?_c('iframe',{staticClass:"info-card-image-background",class:_vm.$vuetify.breakpoint.xlOnly
            ? 'iframe-height-xl'
            : _vm.$vuetify.breakpoint.lgAndUp
            ? 'iframe-height-lg'
            : _vm.$vuetify.breakpoint.mdAndUp
            ? 'iframe-height-md'
            : 'iframe-height-sm',attrs:{"src":_vm.info.video,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndUp ? 'px-10' : '',attrs:{"cols":"12","md":_vm.info.image ? '6' : '12',"justify":"center","align":_vm.$vuetify.breakpoint.mdAndUp && _vm.info.image ? 'start' : 'center'}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"slide-x-transition","min-height":"200"}},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","justify":"center"}},[_c('h2',{staticClass:"text-center py-2 fit-content-width white-space-break-spaces",class:{
                  'title-clean':
                    _vm.colorSet == _vm.setOfColors.CLEAN ||
                    _vm.colorSet == _vm.setOfColors.PRIMARY,
                  'title-secondary': _vm.colorSet == _vm.setOfColors.SECONDARY,
                  'text-sm-h5 text-xl-h4 text-subtitle-1':
                    _vm.demo && !_vm.demoPreview,
                  'text-sm-h4': !_vm.demo || _vm.demoPreview,
                  'px-12': !_vm.demo || (_vm.demo && _vm.$vuetify.breakpoint.smAndUp),
                  'px-3': _vm.demo && !_vm.$vuetify.breakpoint.smAndUp
                }},[_vm._v(" "+_vm._s(_vm.info.title)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","justify":"center"}},[_c('p',{staticClass:"text-md-h6 text-sm-subtitle-1 white-space-break-spaces font-weight-light text-justify"},[_vm._v(" "+_vm._s(_vm.info.content)+" ")])])],1)],1)])],1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }