<template>
  <v-container
    fluid
    class="footer-background content-relative"
    :class="
      $vuetify.breakpoint.smAndUp ? 'footer-height' : 'fit-content-height '
    "
  >
    <v-menu v-if="showOptions" attach offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="7"
          fab
          small
          v-bind="attrs"
          v-on="on"
          icon
          class="content-absolute button-primary"
        >
          <v-icon color="primary">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="modifyContent(content)">
          <v-list-item-title>Editar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-row>
      <v-col cols="12" justify="center" align="center">
        <div class="footer-title fit-content-width">
          <div
            :class="$vuetify.breakpoint.smAndUp ? 'px-12' : 'px-2'"
            class="text-center  py-2 text-sm-h4 fit-content-width white-space-break-spaces"
          >
            {{ content.title }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" justify="center" align="center">
        <div
          :class="$vuetify.breakpoint.smAndUp ? 'px-12' : 'px-2'"
          class="text-center py-2 text-sm-h5 text-subtitle-2  white-space-break-spaces"
        >
          {{ content.content }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" justify="center" align="center">
        <div>
          <v-btn
            icon
            :height="$vuetify.breakpoint.smAndUp ? '60px' : '30px'"
            :width="$vuetify.breakpoint.smAndUp ? '60px' : '30px'"
            href="https://twitter.com/venusalive"
            target="_blank"
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndUp ? '50px' : '25px'"
              color="white"
              >mdi-twitter</v-icon
            >
          </v-btn>
          <v-btn
            icon
            :height="$vuetify.breakpoint.smAndUp ? '60px' : '30px'"
            :width="$vuetify.breakpoint.smAndUp ? '60px' : '30px'"
            href="https://www.instagram.com/venusaterapeuta/"
            target="_blank"
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndUp ? '50px' : '25px'"
              color="white"
              >mdi-instagram</v-icon
            >
          </v-btn>
          <v-btn
            icon
            :height="$vuetify.breakpoint.smAndUp ? '60px' : '30px'"
            :width="$vuetify.breakpoint.smAndUp ? '60px' : '30px'"
            href="https://m.facebook.com/venusaterapeuta"
            target="_blank"
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndUp ? '50px' : '25px'"
              color="white"
              >mdi-facebook</v-icon
            >
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <br />
  </v-container>
</template>

<script>
export default {
  name: "website-footer",
  props: {
    content: {
      type: Object,
      required: true
    },
    showOptions: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    modifyContent(content) {
      this.$parent.$emit("modifyContent", content);
    }
  }
};
</script>

<style scoped>
.fit-content-width {
  width: fit-content;
}
.fit-content-height {
  height: fit-content;
}

.footer-background {
  background-color: var(--v-primary-base);
  color: white;
}
.footer-title {
  border-bottom: solid 3px white;
}
.content-absolute {
  position: absolute;
  margin: 8px;
  background-color: white;
  z-index: 2;
  top: 0;
  right: 0;
}
.content-relative {
  position: relative;
}
.white-space-break-spaces {
  white-space: break-spaces;
}
.button-primary {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
}
</style>
